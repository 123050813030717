import React, { ReactElement } from "react";
import { GetServerSideProps } from "next";
import { getPageProps } from "@services/initial-calls";
import PremiumCoverageWrapper from "@components/layouts/premiumCoverageWrapper";
import MainBanner from "@components/premiumcoverage/mainBanner/mainBanner";
import WhyPartnerWithUs from "@components/premiumcoverage/whyPartnerWithUs";
import HowItWorks from "@components/premiumcoverage/howItWorks";
import Benefits from "@components/premiumcoverage/benefits";
import GetStartedToday from "@components/premiumcoverage/getStartedToday";
import ReadyToJoin from "@components/premiumcoverage/readyToJoin";
import MainForm from "@components/premiumcoverage/mainForm";
export default function PremiumCoverageHome(): ReactElement {
    return (
        <PremiumCoverageWrapper>
            <MainBanner />
            <WhyPartnerWithUs />
            <HowItWorks />
            <Benefits />
            <GetStartedToday />
            <ReadyToJoin />
            <MainForm />
        </PremiumCoverageWrapper>
    );
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    return await getPageProps(context, "home", { fetchForm: true });
};
