import styles from "./style.module.scss";
import { useModalContext } from "@components/torts/modalContext";
import Portal from "@components/premiumcoverage/portal";
import Modal from "@components/torts/shared/modal";
import CloseIcon from "@components/torts/shared/modal/closeIcon";
import Header from "@components/shared/header";
import PremiumCoverageWhiteLogo from "../logos/premiumCoverageWhiteLogo";
import Form from "@components/shared/form";
import { useFormContext } from "@hooks/useFormContext";
import ThankYou from "@components/shared/thankyou";
export default function MainForm() {
    const { modalOpen, setModalOpen } = useModalContext();
    const { formSubmitted } = useFormContext();

    return modalOpen ? (
        <Portal>
            <Modal
                onModalClose={() => setModalOpen(false)}
                backdropClassName={styles["mainModal"]}
                closeOnBackdropClick={false}
            >
                <Header
                    slogan="Protecting Your Home, One Repair at a Time"
                    backGroundColor="transparent"
                    textColor="#fff"
                    iconColor="#fff"
                    useChildrenLogo
                >
                    <PremiumCoverageWhiteLogo />
                </Header>
                <section className="max-w-7xl mx-auto block lg:mt-16 px-2 lg:px-0">
                    <button
                        type="button"
                        onClick={() => setModalOpen(false)}
                        className="block ml-auto mr-2 lg:mr-44 p-[5px]"
                    >
                        <CloseIcon color="#fff" />
                    </button>
                    <div className={`${styles["main-banner"]}`}>
                        {formSubmitted ? (
                            <ThankYou
                                inForm={true}
                                className={styles["thankYouStyle"]}
                            />
                        ) : (
                            <Form
                                classes={{
                                    formClassName: `${styles["form"]}`,
                                    stepTitleClassName: styles["step-title"],
                                    stepDescriptionClassName:
                                        styles["step-description"],
                                    stepClassName: styles["step-block"],
                                    formButtonClassName: styles["form-button"],
                                    formFieldClassName: styles["form-field"],
                                    formStepInnerClassName:
                                        styles["steps-inner"],
                                    fieldsClassName: {
                                        radio: styles["radio-field"],
                                        input: styles["input-field"],
                                        select: styles["select-field"],
                                    },
                                    fieldsWrapperClassName:
                                        styles["fields-wrapper"],
                                    tcpaClassName: styles["tcpa"],
                                    labelAsTitleClassName:
                                        styles["label-as-step-title"],
                                    formProgressbarClassName:
                                        styles["progress-background"],
                                    fieldErrorMessageClassName:
                                        styles["error-style"],
                                    innerThankYouClassName:
                                        styles["thankYouStyle"],
                                }}
                                colors={{
                                    progressBar: "#b0b0b0",
                                }}
                                showProgress={false}
                                scrollToTop={false}
                            />
                        )}
                    </div>
                </section>
            </Modal>
        </Portal>
    ) : (
        <></>
    );
}
