import { ReactNode, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const Portal = ({ children }: { children: ReactNode }) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
        document.body.style.overflow = "hidden";
        document
            .getElementsByTagName("html")[0]
            .classList.add("overflow-hidden");
        document.body.classList.add("overflow-hidden");
        return () => {
            setMounted(false);
            document.body.style.overflow = "";
            document
                .getElementsByTagName("html")[0]
                .classList.remove("overflow-hidden");
            document.body.classList.remove("overflow-hidden");
        };
    }, []);
    return mounted ? createPortal(children, document.body) : null;
};

export default Portal;
