import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
import SharedButton from "../sharedButton";
export default function MainBanner(): ReactElement {
    return (
        <section className={styles["bg-style"]}>
            <div className="flex  pt-16 lg:pt-32 justify-between">
                <div className=" max-w-[640px]">
                    <h1 className="text-5xl lg:text-8xl font-extrabold">
                        Where{" "}
                        <span style={{ color: "#FB6C05" }}>Homeowners</span>{" "}
                        Find You
                    </h1>
                    <p
                        className="mt-4 font-light lg:text-lg"
                        style={{ color: "#464646" }}
                    >
                        Sell your home warranty plans directly to eager
                        homeowners through our marketplace. PremiumCoverage.com
                        handles everything—from showcasing your plans to
                        completing the sale. Ready to grow your business
                        effortlessly? Fill out the form to get started today!
                    </p>
                    <div className="mt-11">
                        <SharedButton>Schedule a Call</SharedButton>
                    </div>
                </div>
                <div className="relative w-[412px] h-[665px]  hidden lg:block">
                    <Image
                        src={
                            "/premiumcoverage/assets/images/premiumCoverageHomeMainImgNew.png"
                        }
                        layout="fill"
                        alt="main image"
                    />
                </div>
            </div>
        </section>
    );
}
